import React from "react";
import { mockedUser } from "../mocks/user.mock";
import Loader from "../components/Loader";
import BottomDrawer from "../components/BottomDrawer";
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

export interface MainAppContextProps {
  user: User | null;
  isLoggedIn: boolean;
  setUser: (user: User, isLoggedIn: boolean) => void;
  showToast: (
    message: string,
    severity: "success" | "error" | "warning" | "info"
  ) => void;
  openDrawer: () => void;
  closeDrawer: () => void;
}

interface MainAppProviderProps {
  children: React.ReactNode;
}

export type User = {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
  photo_url: string;
  auth_date: number;
  hash: string;
  role: string;
};

type State = {
  user: User | null;
  isLoggedIn: boolean;
  loading: boolean;
  btDrawerIsOpen: boolean;
};

export const MainAppContext = React.createContext<MainAppContextProps>({
  user: null,
  isLoggedIn: false,
  setUser: () => {},
  showToast: () => {},
  openDrawer: () => {},
  closeDrawer: () => {},
});

class MainAppProvider extends React.Component<MainAppProviderProps, State> {
  constructor(props: MainAppProviderProps) {
    super(props);
    this.state = {
      user: null,
      isLoggedIn: false,
      loading: true,
      btDrawerIsOpen: false,
    };
  }

  public componentDidMount() {
    // Getting User's Object
    const user = localStorage.getItem("user");
    // Mocking User Account
    // this.setState((prev, next) => ({...prev, user: mockedUser, isLoggedIn: true }));
    if (user) {
      this.setState({ user: JSON.parse(user), isLoggedIn: true });
    }
    setTimeout(() => {
      this.setState({ loading: false });
    }, 2000);
  }

  public setUser = (user: User, isLoggedIn: boolean) => {
    localStorage.setItem("user", JSON.stringify(user));
    this.setState({ user, isLoggedIn });
  };

  public handleDrawerOpen = () => {
    this.setState({ btDrawerIsOpen: true });
  };

  public handleDrawerClose = () => {
    this.setState({ btDrawerIsOpen: false });
  };

  public onDrawerOpen = () => {
    // TODO: Find Out Why It's Not Triggering
    console.log('action!')
  };

  public showToast = (
    message: string,
    severity: "success" | "error" | "warning" | "info"
  ) => {
    enqueueSnackbar(message, { variant: severity });
  };

  public render() {
    const { user, isLoggedIn } = this.state;
    return (
      <MainAppContext.Provider
        value={{
          user,
          isLoggedIn,
          setUser: this.setUser,
          showToast: this.showToast,
          openDrawer: this.handleDrawerOpen,
          closeDrawer: this.handleDrawerClose,
        }}
      >
        <SnackbarProvider maxSnack={5} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          {this.state.loading ? <Loader /> : this.props.children}
          <BottomDrawer
            isOpen={this.state.btDrawerIsOpen}
            onClose={this.handleDrawerClose}
            onOpen={this.onDrawerOpen}
          />
        </SnackbarProvider>
      </MainAppContext.Provider>
    );
  }
}

export default MainAppProvider;
