import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
// import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
// import AdbIcon from '@mui/icons-material/Adb';
import {Link} from "react-router-dom";
import {MainAppContext} from "../providers/MainAppProvider";

const pages = [{title: 'Видео', url: 'media'}, {title: 'Музыка', url: 'audio'}];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

export default class MainAppBar extends React.Component {
    static contextType = MainAppContext;
    context!: React.ContextType<typeof MainAppContext>
    state = {
        anchorElNav: null,
        anchorElUser: null
    }
    constructor(props: any) {
        super(props);
        this.handleCloseNavMenu = this.handleCloseNavMenu.bind(this);
        this.handleCloseUserMenu = this.handleCloseUserMenu.bind(this);
        this.handleOpenNavMenu = this.handleOpenNavMenu.bind(this);
        this.handleCloseNavMenu = this.handleCloseNavMenu.bind(this);
    }
    public handleCloseNavMenu() {
        this.setState({anchorElNav: null});
    };

    public handleCloseUserMenu() {
        this.setState({anchorElUser: null});
    };

    public handleOpenNavMenu(event: React.MouseEvent<HTMLElement>) {
        this.setState({anchorElNav: event.currentTarget});
    }
    public handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        // this.setState({ anchorElUser: event.currentTarget});
    };
    public render() {
        const { user } = this.context;
        return (
            <AppBar position="static" sx={{ background: 'transparent' }}>
                <Container maxWidth="md">
                    <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Link to='/'>
                            <img
                                src={"/logo_main512.png"}
                                alt="logo"
                                width={40}
                                style={{ height: "40px!important" }}
                            />
                        </Link>

                            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={this.handleOpenNavMenu}
                                    color="inherit"
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={this.state.anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(this.state.anchorElNav)}
                                    onClose={this.handleCloseNavMenu}
                                    sx={{ display: { xs: 'block', md: 'none' } }}
                                >
                                    {/*{pages.map((page, index) => (*/}
                                        <MenuItem onClick={this.handleCloseNavMenu}>
                                            <Typography sx={{ textAlign: 'center' }}>Тюленева 25</Typography>
                                        </MenuItem>
                                    {/*))}*/}
                                </Menu>
                            </Box>

                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center', gap: 10 }}>
                            {pages.map((page) => (
                                <Link to={`/${page.url}`} style={{
                                    textDecoration: 'none',
                                    marginTop: 2,
                                    marginBottom: 2,
                                    padding: '0 10px',
                                    fontWeight: 'bold',
                                    color: 'black',
                                }}>
                                    {page.title}
                                </Link>
                            ))}
                        </Box>
                        {user && (
                                <Box sx={{ flexGrow: 0 }}>
                                    <Tooltip title="Open settings">
                                        <IconButton onClick={this.handleOpenUserMenu} sx={{ p: 0 }}>
                                            <Avatar alt={user.username} src={user?.photo_url ? user.photo_url : "/logo_main512.png"} />
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        sx={{ mt: '45px' }}
                                        id="menu-appbar"
                                        anchorEl={this.state.anchorElUser}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(this.state.anchorElUser)}
                                        onClose={this.handleCloseUserMenu}
                                    >
                                        {/*{settings.map((setting) => (*/}
                                        {/*    <MenuItem key={setting} onClick={this.handleCloseUserMenu}>*/}
                                        {/*        <Typography sx={{ textAlign: 'center' }}>{setting}</Typography>*/}
                                        {/*    </MenuItem>*/}
                                        {/*))}*/}
                                    </Menu>
                                </Box>
                            )
                        }
                    </Toolbar>
                </Container>
            </AppBar>
        );
    }

}
