import { Alert, Typography } from "@mui/material";
import "../styles/Feed.css";
import React from "react";
import { MainAppContext } from "../providers/MainAppProvider";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";

export default class Feed extends React.Component<any> {
  static contextType = MainAppContext;
  context!: React.ContextType<typeof MainAppContext>

  public render() {
    // const { isLoggedIn } = this.context;
    return (
      <section className="feed">
        <Card sx={{ marginBottom: 5 }}>
          <CardHeader
              title={this.props.title}
              subheader={this.props.date}
          />
          <CardContent>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              <Typography variant="h6" style={{ marginBottom: 10 }}>
                Информация
              </Typography>
              <Alert severity="warning" style={{ marginBottom: 10 }} variant="outlined" >
                Для бронирования репетиций требуется авторизация через Телеграм
              </Alert>
              <Alert severity="warning" style={{ marginBottom: 10 }} variant="outlined" >
                Регистрация на данный момент недоступна
              </Alert>
              <Alert severity="error" style={{ marginBottom: 10 }} variant="outlined" >
                Распитие алкогольных напитков на территории студии запрещено
              </Alert>
              <Alert severity="warning" style={{ marginBottom: 10, marginTop: 10 }} variant="outlined" >
                Бронирование репетиций на данный момент происходит без модерации и
                подтверждений.
              </Alert>
            </Typography>
          </CardContent>
          {/*<CardActions disableSpacing>*/}
          {/*    <IconButton aria-label="share">*/}
          {/*        <ShareIcon />*/}
          {/*    </IconButton>*/}
          {/*</CardActions>*/}
        </Card>
      </section>
    );
  }
}
