import React from "react";
import Card from "@mui/material/Card/Card";
import Box from "@mui/material/Box/Box";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import PauseIcon from '@mui/icons-material/Pause';
import {CardMedia, LinearProgress} from "@mui/material";

interface AudioPlayerProps {
    track: AudioTrack,
    changeIndex: (index: number) => void,
    index: number,
}

type AudioTrack = {
    title: string;
    artist: string;
    src: string;
    image: string;
}

export default class AudioPlayer extends React.Component<AudioPlayerProps> {
    state = {
        isPlaying: false,
        progress: 0,
        index: 0,
    }
    audioRef: React.MutableRefObject<HTMLAudioElement | null> = React.createRef();
    intervalRef: React.MutableRefObject<any> = React.createRef();
    isReady: React.MutableRefObject<boolean | null> = React.createRef();

    constructor(props: AudioPlayerProps) {
        super(props);
        this.audioRef.current = new Audio(this.props.track.src);
        // this.intervalRef.current = setInterval(() => {});
        this.isReady.current = false;
        this.startTimer = this.startTimer.bind(this);
        this.playPause = this.playPause.bind(this);
        this.toNextTrack = this.toNextTrack.bind(this);
        this.toPrevTrack = this.toPrevTrack.bind(this);
    }

    public componentDidUpdate(prevProps: AudioPlayerProps) {
        if (this.state.isPlaying) {
            this.audioRef.current?.play();
            this.startTimer();
        } else {
            this.audioRef.current?.pause();
        }
        // this.audioRef.current?.pause();
        // this.audioRef.current = new Audio(this.props.src);
        // this.setState({ progress: this.audioRef.current?.currentTime });
        //
        // if (this.isReady.current) {
        //     this.audioRef.current.play();
        //     this.setState({ isPlaying: true });
        //     this.startPlaying();
        // } else {
        //     this.isReady.current = true;
        // }
    }

    public componentWillUnmount() {
        this.audioRef.current?.pause();
        this.audioRef.current = null;
        clearInterval(this.intervalRef.current);
    }

    public playPause() {
        this.setState({ isPlaying: !this.state.isPlaying });
    }

    public startTimer() {
        // Clear any timers already running
        clearInterval(this.intervalRef.current);
        this.intervalRef.current = setInterval(() => {
            if (this.audioRef.current) {
                if (this.audioRef.current?.ended) {
                    // Turn next track
                    this.toNextTrack();
                } else {
                    // Updating track's progress
                    this.setState({ progress: this.audioRef.current?.currentTime / this.audioRef.current?.duration * 100 });
                }
            }
        })
    }

    public toNextTrack() {
        this.props.changeIndex(this.props.index + 1);
        this.audioRef.current?.pause();
        this.audioRef.current = new Audio(this.props.track.src);
    }

    public toPrevTrack() {
        this.props.changeIndex(this.props.index - 1);
        this.audioRef.current?.pause();
        this.audioRef.current = new Audio(this.props.track.src);
    }

    public render() {
        return (
            <Card sx={{ display: 'flex', flexDirection: 'column', position: 'absolute', bottom: 60, left: 0, right: 0, margin: 'auto', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                            <Typography component="div" variant="h5">
                                {this.props.track.title}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                component="div"
                                sx={{ color: 'text.secondary' }}
                            >
                                {this.props.track.artist}
                            </Typography>
                        </CardContent>
                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                            <IconButton aria-label="previous" onClick={this.toPrevTrack}>
                                {/*<SkipNextIcon /> */}
                                <SkipPreviousIcon />
                            </IconButton>
                            <IconButton aria-label="play/pause" onClick={this.playPause}>
                                {!this.state.isPlaying ? <PlayArrowIcon sx={{ height: 38, width: 38 }} /> : <PauseIcon sx={{ height: 38, width: 38 }} />}
                            </IconButton>
                            <IconButton aria-label="next" onClick={this.toNextTrack}>
                                {/*<SkipPreviousIcon />*/}
                                <SkipNextIcon />
                            </IconButton>
                        </Box>

                    </Box>
                    <CardMedia
                        component="img"
                        sx={{ width: 151, maxHeight: 150 }}
                        image={this.props.track.image}
                        // image="/assets/room.jpg"
                        alt={this.props.track.title + ' - ' + this.props.track.artist}
                    />
                </Box>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" value={this.state.progress} />
                </Box>
            </Card>
        );
    }
}
