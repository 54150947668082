import React from "react";
import '../styles/Audio.css';
import AudioPlayer from "../features/audio-player";
import {
    Avatar,
    Button,
    CardActions,
    CardMedia,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TrackListItem from "../features/home-section/TrackListItem.component";

const tracklist = [
    {
        title: '7000',
        src: '/audio-files/2025/seven_k0_4.mp3',
        image: '/assets/album-covers/7000.png',
        artist: 'Подвальный джем',
    },
    {
        title: 'Космос',
        src: '/audio-files/2025/kosmos_project0_13.mp3',
        image: '/assets/album-covers/space.png',
        artist: 'Подвальный джем',
    },
    {
        title: 'Моя комната',
        src: '/audio-files/2023-2024/27022024_1.mp3',
        image: '/assets/album-covers/room.jpg',
        artist: 'Подвальный джем',
    }
]

export default class Audio extends React.Component {
    state = {
        currentIndex: 0,
        playing: false,
    }
    constructor(props: any) {
        super(props);
        this.setCurrentTrackIndex = this.setCurrentTrackIndex.bind(this);
        this.setTrackPlaying = this.setTrackPlaying.bind(this);
    }

    public setCurrentTrackIndex(index: number) {
        this.setState({currentIndex: index});
    }

    public get trackIndex(): number {
        return this.state.currentIndex === tracklist.length - 1 ? -1 : this.state.currentIndex;
    }

    public setTrackPlaying(index: number) {
        if (this.state.currentIndex === index) {
            this.setState({ playing: false });
        } {
            this.setState({currentIndex: index, playing: true});
        }
    }

    public render() {
        return (
            <section className="Audio">
                <div className="Audio__container">
                    <Card>
                        <CardMedia
                            sx={{ height: 140 }}
                            image="/assets/album-covers/wall.jpeg"
                            title="green iguana"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Подвальный джем
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                Подвальные демо 2025
                            </Typography>
                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                {tracklist.map((track, index) => (
                                    <TrackListItem
                                        key={index}
                                        index={index}
                                        title={track.title}
                                        artist={track.artist}
                                        isPlaying={index === this.state.currentIndex && this.state.playing}
                                        onClick={this.setTrackPlaying}
                                    />
                                ))}
                            </List>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                Демо записи сделанные в 2025 году на студии Тюленева 25.
                            </Typography>
                        </CardContent>
                        {/*<CardActions>*/}
                        {/*    <Button size="small">Share</Button>*/}
                        {/*    <Button size="small">Learn More</Button>*/}
                        {/*</CardActions>*/}
                    </Card>
                    {this.state.playing && (
                        <AudioPlayer
                           track={tracklist[this.state.currentIndex]}
                           changeIndex={this.setCurrentTrackIndex}
                           index={this.trackIndex}
                        />
                    )}
                </div>
            </section>
        )
    }
}
