import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./styles/App.css";
import Loader from "./components/Loader";
import InstallPWA from "./components/InstallPrompt";
import MainAppProvider from "./providers/MainAppProvider";
import Feed from "./screens/Feed";
import InfoModal from "./components/InfoModal";
import Timetable from "./screens/Timetable";
import Invite from "./screens/Invite";
import UserList from "./screens/UserList";
// import LabelBottomNavigation from "./components/BottomNavigation";
import Audio from "./screens/Audio";
import Media from "./screens/Media";
import MainAppBar from "./components/MainAppBar";

class App extends React.Component {
    render(): React.ReactNode {
        return (
          <Router>
            <MainAppProvider>
                <MainAppBar />
                <InstallPWA />
                <InfoModal />
                <Suspense fallback={<Loader />}>
                  <Routes>
                    <Route path="/" element={<Timetable />} />
                    <Route path="/audio" element={<Audio />} />
                    <Route path="/info" element={<Feed />} />
                    <Route path="/media" element={<Media />} />
                    <Route path="/invite" element={<Invite />} />
                    <Route path="/users" element={<UserList />} />
                  </Routes>
                </Suspense>
                {/*<LabelBottomNavigation />*/}
            </MainAppProvider>
          </Router>
      );
  }
}

export default App;
