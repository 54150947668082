export interface HourType {
  value: string;
  isSelected: boolean;
  isBooked: boolean;
}

export interface BookingData {
  [key: string]: {
    status: BookingType;
    owner: string | null;
    userId: number | null,
    bandName: string | null;
    avatar: string | null;
    isCancelling: boolean;
  };
}

export enum BookingStatus {
  NONE = "NONE",
  SELECTED = "SELECTED",
  BOOKED = "BOOKED",
  CONFIRMED = "CONFIRMED",
  CANCELLING = "CANCELLING",
}

export type BookingType = keyof typeof BookingStatus;
