import React from 'react';
import {MainAppContext} from "../providers/MainAppProvider";
import {Alert, Button, CardActionArea, CardActions, CardMedia, Typography} from "@mui/material";
import { LoginButton } from '@telegram-auth/react';
import {ADD_USER_URL, API_URL} from "../constants/endpoints";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
// import {mockedUser} from "../mocks/user.mock";

export default class Invite extends React.Component {
    static contextType = MainAppContext;
    context!: React.ContextType<typeof MainAppContext>

    constructor(props: any ) {
        super(props);
        this.handleTelegramResponse = this.handleTelegramResponse.bind(this);
    }

    public async handleTelegramResponse (user: any) {
        const { showToast } = this.context;
        try {
            await fetch(API_URL + ADD_USER_URL, {
                method: "POST",
                body: JSON.stringify({ ...user, role: 'member' }),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            // const { userData } = await response.json();
            localStorage.setItem("user", JSON.stringify({ ...user, role: 'member' }));
        } catch (e) {
            console.error(e);
            showToast('Ошибка авторизации', 'error');
        }
    }

    public render() {
        const urlParams = new URLSearchParams(window.location.search);
        const id = !!urlParams.get("id");
        return (
            <section style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Card sx={{ maxWidth: 400 }}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            image="/assets/logo_seal.png"
                            alt="Тюленева 25, Музыальная студия"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Тюленева 25
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                Авторизация в приложении проходит с помощью Telegram, так быстрее, и не надо заводить
                                новых непонятных аккаунтов, сочинять и запоминать пароли.
                            </Typography>
                            <Alert severity="info" style={{ marginTop: 10 }} variant="outlined" >
                                Приложение должно быть установлено на устройство
                            </Alert>
                            {!id && (
                                <Alert severity="warning" style={{ marginTop: 10 }} variant="outlined" >
                                    Авторизация на данный момент недоступна
                                </Alert>
                            )}
                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        {id && <LoginButton
                            botUsername={'tuleneva25_bot'}
                            onAuthCallback={this.handleTelegramResponse}
                        />}
                    </CardActions>
                </Card>
            </section>
        );
    }
}
