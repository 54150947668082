export const video_urls = [
    {
        url: "https://dzen.ru/embed/v3FVSxFyHzVU?from_block=partner&from=zen&mute=1&autoplay=0&tv=0",
        title: `Малхолланд Драйв  / Adam's Wine`,
        description: "Со своим посвящением великому и ужасному Дэвиду Линчу у нас на студии зажгли Adam's Wine",
        date: "09.02.2025"
    },
    {
        url: "https://dzen.ru/embed/vEJvFYQOSt1A?from_block=partner&from=zen&mute=1&autoplay=0&tv=0",
        title: "Космос",
        description: "Чтобы устроить этот джем, пришлось довольно-таки долго договариваться и согласовывать графики всех участников. Однако результат стоил того.",
        date: "31.01.2025"
    },
    {
        url: "https://dzen.ru/embed/vjf8TsVf-pT0?from_block=partner&from=zen&mute=1&autoplay=0&tv=0",
        title: "7000",
        description: "Разгоняли песню про путешествие по Европе. Одна из первых попыток снимать видео на несколько ракурсов. Получилось довольно таки бодро.",
        date: "12.11.2024"
    },
    {
        url: "https://dzen.ru/embed/vbJa6xMOUgVY?from_block=partner&from=zen&mute=1&autoplay=0&tv=0",
        title: "Страх пробуждения",
        description: "Небольшой отрывок из довольно продолжительного джема. ",
        date: "11.11.2024"
    },
    {
        url: "https://dzen.ru/embed/v8bihdUdtyko?from_block=partner&from=zen&mute=1&autoplay=0&tv=0",
        title: "Нет слов",
        description: "Небольшой крик души. Жарким летним вечером, засели в подвале на студии и устроили стонер джем.",
        date: "19.06.2024"
    },
]
