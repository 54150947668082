import {Avatar, ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import React from "react";
import PauseIcon from "@mui/icons-material/Pause";

interface TrackListItemProps {
    title: string;
    artist: string;
    index: number;
    isPlaying: boolean;
    onClick: (index: number) => void;
}

export default class TrackListItem extends React.Component<TrackListItemProps> {
    constructor(props: TrackListItemProps) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    public handleClick() {
        this.props.onClick(this.props.index);
    }

    public render() {
        return (
            <ListItem onClick={this.handleClick}>
                <ListItemAvatar>
                    <Avatar>
                        {!this.props.isPlaying ? <PlayArrowIcon sx={{ height: 38, width: 38 }} /> : <PauseIcon sx={{ height: 38, width: 38 }} />}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={this.props.title} secondary={this.props.artist} />
            </ListItem>
        );
    }
}
