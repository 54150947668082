
import React from "react";
import {video_urls} from "../constants/video_urls";
import VideoCard from "../components/VideoCard";
import '../styles/Media.css';

export default class Media extends React.Component {
    public render() {
        return (
          <section className="Media">
              <div className="Media__container">
                  {video_urls.map((url, i) => (
                      <VideoCard {...url} key={i} />
                  ))}
              </div>
          </section>
        );
    }
}
