import React from "react";
import { BookingData } from "../../types/hours.types";
import "react-calendar/dist/Calendar.css";
import HourButton from "./HourButton.component";

interface HourPickerProps {
  data: BookingData;
  userId: number | undefined;
  onClick: (hour: string, isOwn?: boolean) => void;
}

export default class HourPicker extends React.Component<HourPickerProps> {
  public render() {
    const hours = Object.keys(this.props.data);
    return (
        <div className="hourPicker">
          {/** Buttons for hours selection */}
          {hours.map((hour: string, index: number) => (
              <HourButton
                  key={index}
                  hour={hour}
                  data={this.props.data}
                  isOwn={this.props.data[hour].userId === this.props.userId}
                  onClick={this.props.onClick}
              />
          ))}
        </div>
    );
  }
}
