import React from "react";

export default class HomeSection extends React.Component {
    public render() {
        return (
            <section className='container'>
                <figure style={{ maxWidth: 500 }}>
                    <img src='/assets/poster_white.jpg' alt='Тюленева 25, Музыкальная студия' style={{ maxWidth: '100%'}}/>
                    <figcaption></figcaption>
                </figure>
            </section>
        );
    }
}
