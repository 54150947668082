import React from 'react';
import {MainAppContext, User} from "../providers/MainAppProvider";
import {API_URL, USER_LIST_URL} from "../constants/endpoints";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import Avatar from "@mui/material/Avatar/Avatar";

type State = {
    users: User[];
}

export default class UserList extends React.Component<any, State> {
    static contextType = MainAppContext;
    state = {
        users: [],
    }

    constructor(props: any, public context: React.ContextType<typeof MainAppContext>) {
        super(props);
        this.fetchUsers = this.fetchUsers.bind(this);
    }

    public componentDidMount() {
        this.fetchUsers().then();
    }

    public render() {
        console.log('user list: ', this.state.users);
        return (
            <section>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>avatar</TableCell>
                                <TableCell>
                                    username
                                    </TableCell>
                                <TableCell>
                                    auth date
                                </TableCell>
                                <TableCell>id</TableCell>
                                <TableCell>role</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.users.map((user: User, index: number) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        <Avatar
                                            imgProps={{
                                                sx: {
                                                    backgroundImage: "url(/assets/vinyl.svg)",
                                                    backgroundSize: "contain",
                                                },
                                            }}
                                        >
                                            <img
                                                src={
                                                    user?.photo_url ? user.photo_url : "/logo_main512.png"
                                                }
                                                alt="logo"
                                                width={40}
                                                style={{ height: "40px!important" }}
                                            />
                                        </Avatar>
                                    </TableCell>
                                    <TableCell>{user?.username}</TableCell>
                                    <TableCell>{new Date(user?.auth_date * 1000).toLocaleDateString()}</TableCell>
                                    <TableCell>{user?.id}</TableCell>
                                    <TableCell>{user.role}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </section>
        );
    }

    public async fetchUsers() {
        const {showToast} = this.context;
        try {
            const response = await fetch(API_URL + USER_LIST_URL, { method: "POST" });
            const data = await response.json();
            console.log('data: ', data);
            this.setState({users: data});
        } catch (e) {
            console.error(e);
            showToast('Не удалось загрузить список пользователей', 'error');
        }
    }
}
