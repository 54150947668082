import React from "react";
import '../styles/Timetable.css';
import {MainAppContext} from "../providers/MainAppProvider";
import HomeSection from "../features/home-section";
import Booking from '../features/booking-rehearsal';

export default class Timetable extends React.Component {
  static contextType = MainAppContext;
  context!: React.ContextType<typeof MainAppContext>

  public render() {
    const { isLoggedIn } = this.context;
    if (isLoggedIn) {
      return <Booking />;
    } else {
      return <HomeSection />;
    }
  }
}

