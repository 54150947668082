// import { BookingStatus } from "../constants/enums";
import { BookingData, BookingStatus, HourType } from "../types/hours.types";

export const mockedHours: HourType[] = [
    {
      value: "12:00",
      isSelected: false,
      isBooked: false,
    },
    {
      value: "13:00",
      isSelected: false,
      isBooked: false,
    },
    {
      value: "14:00",
      isSelected: false,
      isBooked: false,
    },
    {
      value: "15:00",
      isSelected: false,
      isBooked: false,
    },
    {
      value: "16:00",
      isSelected: false,
      isBooked: false,
    },
    {
      value: "17:00",
      isSelected: false,
      isBooked: false,
    },
    {
      value: "18:00",
      isSelected: false,
      isBooked: false,
    },
    {
      value: "19:00",
      isSelected: false,
      isBooked: false,
    },
    {
      value: "20:00",
      isSelected: false,
      isBooked: false,
    },
    {
      value: "21:00",
      isSelected: false,
      isBooked: false,
    },
    {
      value: "22:00",
      isSelected: false,
      isBooked: false,
    },
    {
      value: "23:00",
      isSelected: false,
      isBooked: false,
    },
  ];

  export const initialBookingState: BookingData = {
    '12:00': {
      status: BookingStatus.NONE,
      owner: null,
      userId: null,
      bandName: '',
      avatar: null,
      isCancelling: false
    },
    '13:00': {
      status: BookingStatus.NONE,
      owner: null,
      userId: null,
      bandName: '',
      avatar: null,
      isCancelling: false
    },
    '14:00': {
      status: BookingStatus.NONE,
      owner: null,
      userId: null,
      bandName: '',
      avatar: null,
      isCancelling: false
    },
    '15:00': {
      status: BookingStatus.NONE,
      owner: null,
      userId: null,
      bandName: '',
      avatar: null,
      isCancelling: false
    },
    '16:00': {
      status: BookingStatus.NONE,
      owner: null,
      userId: null,
      bandName: '',
      avatar: null,
      isCancelling: false
    },
    '17:00': {
      status: BookingStatus.NONE,
      owner: null,
      userId: null,
      bandName: '',
      avatar: null,
      isCancelling: false
    },
    '18:00': {
      status: BookingStatus.NONE,
      owner: null,
      userId: null,
      bandName: '',
      avatar: null,
      isCancelling: false
    },
    '19:00': {
      status: BookingStatus.NONE,
      owner: null,
      userId: null,
      bandName: '',
      avatar: null,
      isCancelling: false
    },
    '20:00': {
      status: BookingStatus.NONE,
      owner: null,
      userId: null,
      bandName: '',
      avatar: null,
      isCancelling: false
    },
    '21:00': {
      status: BookingStatus.NONE,
      owner: null,
      userId: null,
      bandName: '',
      avatar: null,
      isCancelling: false
    },
    '22:00': {
      status: BookingStatus.NONE,
      owner: null,
      userId: null,
      bandName: '',
      avatar: null,
      isCancelling: false
    },
    '23:00': {
      status: BookingStatus.NONE,
      owner: null,
      userId: null,
      bandName: '',
      avatar: null,
      isCancelling: false
    }
  }
