import React from "react";
import {BookingData, BookingStatus} from "../../types/hours.types";
import { Avatar, CardHeader, Chip, Tooltip, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

interface HourButtonProps {
  hour: string;
  data: BookingData;
  isOwn: boolean;
  onClick: (hour: string, isOwn?: boolean) => void;
}

class HourButton extends React.Component<HourButtonProps> {
  constructor(props: HourButtonProps) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  public handleClick() {
    this.props.onClick(this.props.hour, this.props.isOwn);
  }

  public render() {
    const { data, hour, isOwn } = this.props;
    const { avatar, owner, bandName, status, isCancelling  } = data[hour];
    const isDisabled =
      (status === BookingStatus.CONFIRMED || status === BookingStatus.BOOKED) && !isOwn;
    return (
      <Tooltip
        disableHoverListener={!owner}
        disableFocusListener={!owner}
        disableTouchListener={!owner}
        enterTouchDelay={0}
        title={
          <CardHeader
            sx={{ color: "#fff" }}
            avatar={
              <Avatar
                alt={ owner!!}
                src={ avatar ? avatar : "/assets/vinyl.svg" }
                imgProps={{
                  sx: {
                    backgroundImage: "url(/assets/vinyl.svg)",
                    backgroundSize: "contain",
                  },
                }}
              />
            }
            title={
              <Typography
                gutterBottom
                noWrap
                sx={{ overflow: "hidden", color: "#fff" }}
              >
                {owner}
              </Typography>
            }
            subheader={
              <>
                {bandName && bandName !== "" && (
                    <Chip
                      label={bandName}
                      variant="outlined"
                      sx={{
                        color: "#fff",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: 200,
                      }}
                    />
                  )}
                <Chip
                  icon={<AccessTimeIcon sx={{ fill: "#fff" }} />}
                  label={hour}
                  sx={{ color: "#fff" }}
                />
              </>
            }
          />
        }
      >
        <button
          className={"hour " + status + " " + (isCancelling ? "CANCELLING" : "")}
          disabled={isDisabled}
          onClick={this.handleClick}
        >
          {owner ? (
            <Avatar
              alt={owner}
              src={avatar ? avatar : "/assets/vinyl.svg"}
              imgProps={{
                sx: {
                  backgroundImage: "url(/assets/vinyl.svg)",
                  backgroundSize: "contain",
                  width: 15,
                  height: 15,
                },
              }}
              sx={{ width: 15, height: 15, border: "1px solid" }}
            />
          ) : hour }
        </button>
      </Tooltip>
    );
  }
}

export default HourButton;
