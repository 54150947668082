import React from "react";
import CardHeader from "@mui/material/CardHeader";
// import Avatar from "@mui/material/Avatar";
// import IconButton from "@mui/material/IconButton";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
// import CardActions from "@mui/material/CardActions";
// import FavoriteIcon from "@mui/icons-material/Favorite";
// import ShareIcon from "@mui/icons-material/Share";
// import Collapse from "@mui/material/Collapse";
import Card from "@mui/material/Card";

interface VideoCardProps {
    title: string;
    description: string;
    url: string;
    date: string;
}

export default class VideoCard extends React.Component<VideoCardProps> {
    public render() {
        return (
            <Card sx={{ marginBottom: 5 }}>
                <CardHeader
                    title={this.props.title}
                    subheader={this.props.date}
                />
                <iframe width="100%" height="270"
                        src={this.props.url}
                        allow="autoplay; fullscreen; accelerometer; gyroscope; picture-in-picture; encrypted-media"
                        data-testid="embed-iframe" frameBorder="0" scrolling="no"
                        allowFullScreen></iframe>
                <CardContent>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {this.props.description}
                    </Typography>
                </CardContent>
                {/*<CardActions disableSpacing>*/}
                {/*    <IconButton aria-label="share">*/}
                {/*        <ShareIcon />*/}
                {/*    </IconButton>*/}
                {/*</CardActions>*/}
            </Card>
        )
    }
}
