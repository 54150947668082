import Alert from "@mui/material/Alert/Alert";
import Box from "@mui/material/Box/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer/SwipeableDrawer";
import Typography from "@mui/material/Typography/Typography";
import React from "react";
// import { Link } from "react-router-dom";

interface BottomDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}

export default class BottomDrawer extends React.Component<
  BottomDrawerProps,
  any
> {
  render() {
    return (
      <SwipeableDrawer
        container={
          window !== undefined ? () => window.document.body : undefined
        }
        anchor="bottom"
        open={this.props.isOpen}
        onClose={this.props.onClose}
        onOpen={this.props.onOpen}
        swipeAreaWidth={56}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Box>
          <Box
            sx={{
              width: 30,
              height: 6,
              backgroundColor: "grey",
              borderRadius: 3,
              position: "absolute",
              top: 8,
              left: "calc(50% - 15px)",
            }}
          />
          <Typography sx={{ p: 2, color: "text.secondary" }}>
            Информация
          </Typography>
        </Box>
        <Box sx={{ px: 2, pb: 2, height: "100%", overflow: "auto" }}>
          <Alert
            severity="warning"
            style={{ marginBottom: 10 }}
            variant="outlined"
          >
            Для бронирования репетиций требуется авторизация через Телеграм
          </Alert>
          <Alert severity="info" variant="outlined" style={{ margin: "10px 0" }}>
            Регистрация временно недоступна
          </Alert>
          <Alert
            severity="warning"
            style={{ marginBottom: 10, marginTop: 10 }}
            variant="outlined"
          >
            Бронирование репетиций на данный момент происходит без модерации и
            подтверждений.
          </Alert>
            {/*<Link to="/reg">Авторизация</Link>*/}
        </Box>
      </SwipeableDrawer>
    );
  }
}
