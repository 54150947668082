import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { BookingStatus } from "../../types/hours.types";

interface CancelModalProps {
  isOpen: boolean;
  hours: string[];
  onClose: () => void;
  onCancel: () => void;
}

class CancelModal extends React.Component<CancelModalProps> {
  public render() {
    return (
      <Dialog
        open={this.props.isOpen}
        onClose={this.props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{
            textAlign: "left",
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" fontSize={18}>
            Отменить бронирование
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <div className="hourChecker">
            {this.props.hours.map((hour, i) => (
              <button key={i} className={"hour " + BookingStatus.CANCELLING}>
                {hour}
              </button>
            ))}
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={this.props.onClose} color="error">Закрыть</Button>
          <Button onClick={this.props.onCancel} autoFocus color="success">
            Подтвердить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default CancelModal;
